import React from 'react';

import { Section } from '../components/molecules/section';
import { MarkdownOnImage, MarkdownOnImageProps } from '../components/molecules/markdown-on-image';
import { AlignedMarkdown, AlignedMarkdownProps } from '../components/atoms/aligned-markdown';
import {
  CardOverlaidTextMany,
  CardOverlaidTextManyProps,
  CardOverlaidTextWithMarkdown,
  CardOverlaidTextWithMarkdownProps,
} from '../components/organisms/card-overlaid-text';
import { useTheme } from '@emotion/react';
import { TestimonialsWithImages, TestimonialsWithImagesProps } from '../components/organisms/testimonials-with-images';

export type OurMissionTemplateProps = {
  title: string;
  introduction?: MarkdownOnImageProps;
  firstTextSection?: AlignedMarkdownProps;
  secondTextSection?: AlignedMarkdownProps;
  thirdTextSection?: AlignedMarkdownProps;
  textWithKpi?: CardOverlaidTextWithMarkdownProps;
  multipleKpi?: CardOverlaidTextManyProps;
  testimonials?: TestimonialsWithImagesProps;
};

export const OurMissionTemplate: React.FC<OurMissionTemplateProps> = props => {
  const theme = useTheme();

  return (
    <>
      {props.introduction && (
        <Section firstChildMarginPreset="bottom">
          <MarkdownOnImage {...props.introduction} />
        </Section>
      )}

      {props.firstTextSection && (
        <Section marginPreset="sides">
          <AlignedMarkdown {...props.firstTextSection} />
        </Section>
      )}

      {props.textWithKpi && (
        <Section marginPreset="bottom">
          <CardOverlaidTextWithMarkdown {...props.textWithKpi} />
        </Section>
      )}

      {props.secondTextSection && (
        <Section marginPreset="bottom">
          <AlignedMarkdown {...props.secondTextSection} />
        </Section>
      )}

      {props.testimonials && (
        <Section marginPreset="bottom">
          <TestimonialsWithImages {...props.testimonials} />
        </Section>
      )}

      {props.thirdTextSection && (
        <Section marginPreset="sides" firstChildMarginPreset="bottom">
          <AlignedMarkdown {...props.thirdTextSection} />
        </Section>
      )}

      {props.multipleKpi && (
        <Section marginPreset="none" background={theme.color.secondary}>
          <CardOverlaidTextMany {...props.multipleKpi} />
        </Section>
      )}
    </>
  );
};
