import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import { parseLocale } from '../locale';
import SEO from '../components/seo';
import { OurMissionTemplate } from '../templates/our-mission.template';
import { OurMissionQuery } from '../../graphql-types';
import {
  mapFragmentToAlignedMarkdownProps,
  mapFragmentToTextOnBackgroundImageProps,
} from '../data-mappers/markdown.mapper';
import {
  mapFragmentToCardOverlaidTextManyProps,
  mapFragmentToCardOverlaidTextWithMarkdownProps,
} from '../data-mappers/kpi.mapper';
import { mapFragmentToTestimonialsWithImagesProps } from '../data-mappers/testimonials-with-images.mapper';
import { safeApply } from '../data-mappers/utils';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { useLocalizedSlugs } from '../hooks/useLocalizedSlugs';

export const query = graphql`
  query OurMission($language: String) {
    cms {
      ourMission(locale: $language) {
        title
        introduction {
          ...TextOnBackgroundImage
        }

        FirstTextSection {
          ...AlignedMarkdown
        }

        SecondTextSection {
          ...AlignedMarkdown
        }

        ThirdTextSection {
          ...AlignedMarkdown
        }

        MultipleKpi {
          ...MultipleKpiCards
        }

        KpiCardWithMarkdown {
          ...KpiCardWithMarkdown
        }

        Testimonials {
          ...TestimonialsWithImages
        }
      }
    }
  }
`;

const OurMission: React.FC<{ data: OurMissionQuery }> = ({ data }) => {
  const intl = useIntl();
  const locale = parseLocale(intl.locale);

  const { cms } = data;

  const props = {
    title: cms.ourMission?.title ?? '',
    introduction: safeApply(
      value => mapFragmentToTextOnBackgroundImageProps(value, locale),
      cms.ourMission?.introduction
    ),
    firstTextSection: safeApply(value => mapFragmentToAlignedMarkdownProps(value), cms.ourMission?.FirstTextSection),
    secondTextSection: safeApply(value => mapFragmentToAlignedMarkdownProps(value), cms.ourMission?.SecondTextSection),
    thirdTextSection: safeApply(value => mapFragmentToAlignedMarkdownProps(value), cms.ourMission?.ThirdTextSection),
    textWithKpi: safeApply(
      value => mapFragmentToCardOverlaidTextWithMarkdownProps(value, locale),
      cms.ourMission?.KpiCardWithMarkdown
    ),
    multipleKpi: safeApply(value => mapFragmentToCardOverlaidTextManyProps(value, locale), cms.ourMission?.MultipleKpi),
    testimonials: safeApply(
      value => mapFragmentToTestimonialsWithImagesProps(value, locale),
      cms.ourMission?.Testimonials
    ),
  };

  useBreadcrumbs({
    pageTitle: props.title,
    contentCategory: intl.formatMessage({ id: 'kering_foundation' }),
  });

  useLocalizedSlugs({
    slugs: [
      { locale: 'en', slug: 'our-mission' },
      { locale: 'fr', slug: 'notre-mission' },
    ],
  });

  return (
    <>
      <SEO lang={locale} title={props.title} />
      <OurMissionTemplate {...props} />
    </>
  );
};

export default OurMission;
